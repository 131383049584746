<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="11" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                <ValidationProvider name="Warehouse State" vid='warehouse_state' rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="warehouse_state"
                    slot-scope="{ valid, errors }"
                    >
                     <template v-slot:label>
                      {{ $t('warehouse_config.warehouse_state')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-radio-group
                        v-model="warehouse_info.warehouse_state"
                        :options="options"
                        class="mb-3"
                        value-field="item"
                        text-field="name"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-radio-group>
                    <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Warehouse Type" vid='warehouse_type_id' rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="warehouse_type_id"
                    slot-scope="{ valid, errors }"
                    >
                     <template v-slot:label>
                      {{ $t('warehouse_config.warehouse_type')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="warehouse_info.warehouse_type_id"
                      :options="warehouseTypeList"
                      id="warehouse_type_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Warehouse Category" vid='warehouse_category_id' rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="warehouse_category_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{ $t('warehouse_config.warehouse_category')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="warehouse_info.warehouse_category_id"
                        :options="warehouseCategoryList"
                        id="warehouse_category_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Warehouse Name (En)" vid='warehouse_name' rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="warehouse_name"
                    slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{ $t('warehouse_config.warehouse_namef')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="warehouse_name"
                      v-model="warehouse_info.warehouse_name"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Warehouse Name (Bn)" vid='warehouse_name_bn'>
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="warehouse_name_bn"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                      {{ $t('warehouse_config.warehouse_name_bn')}} <span class="text-danger"></span>
                    </template>
                    <b-form-input
                      id="warehouse_name_bn"
                      v-model="warehouse_info.warehouse_name_bn"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Division" vid='division_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="division"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="warehouse_info.division_id"
                      :options="divisionList"
                      id="division_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                 <ValidationProvider name="Region" vid='region_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="division"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('warehouse_config.region')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="warehouse_info.region_id"
                      :options="regionLists"
                      id="region_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="District" vid='district_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="district"
                    slot-scope="{ valid, errors }"
                    >
                     <template v-slot:label>
                      {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="warehouse_info.district_id"
                      :options="regionDistrictLists"
                      id="district_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Upazila" vid='upazilla_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="upazila_id"
                    slot-scope="{ valid, errors }"
                    >
                     <template v-slot:label>
                      {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="warehouse_info.upazilla_id"
                      :options="upazilaList"
                      id="upazila_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Union" vid='union_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="union_id"
                    slot-scope="{ valid, errors }"
                    >
                     <template v-slot:label>
                      {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="warehouse_info.union_id"
                      :options="unionList"
                      id="union_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Warehouse capacity" vid='total_capacity' rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="warehouse_capacity"
                    slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{ $t('warehouse_config.warehouse_capacity')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="warehouse_capacity"
                      type="number"
                      v-model="warehouse_info.total_capacity"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Warehouse actual capacity" vid='actual_capacity' rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="warehouse_actual_capacity"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                      {{ $t('warehouse_config.warehouse_actual_capacity')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="warehouse_actual_capacity"
                      type="number"
                      v-model="warehouse_info.actual_capacity"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="File Name (En)" vid='file_name'>
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="warehouse_file_namef"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                      {{ $t('warehouse_config.warehouse_file_namef')}} <span class="text-danger"></span>
                    </template>
                    <b-form-input
                      id="warehouse_file_name"
                      v-model="warehouse_info.file_name"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="File Name (Bn)" vid='file_name_bn' >
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="warehouse_file_name_bn"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                      {{ $t('warehouse_config.warehouse_file_name_bn')}} <span class="text-danger"></span>
                    </template>
                    <b-form-input
                      id="warehouse_file_name_bn"
                      v-model="warehouse_info.file_name_bn"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                 <ValidationProvider name="Attachment" vid="attachment">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label=" $t('warehouse_config.warehouse_attachments')"
                    label-for="warehouse_attachments"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                   {{ $t('warehouse_config.warehouse_attachments')}}<span class="text-danger"></span>
                    </template>
                    <b-form-file
                    id="warehouse_attachments"
                    v-on:change="onFileChange"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-file>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                    </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Remarks" rules="">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('warehouse_config.warehouse_remarks')"
                    label-for="remarks"
                    slot-scope="{ valid, errors }"
                  >
                  <vue-editor
                  id="remarks"
                  useCustomImageHandler
                  v-model="warehouse_info.remarks"
                  :state="errors[0] ? false : (valid ? true : null)"
                  ></vue-editor>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Remarks (Bn)" rules="">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('warehouse_config.warehouse_remarks_bn')"
                    label-for="remarks_bn"
                    slot-scope="{ valid, errors }"
                  >
                  <vue-editor
                  id="remarks_bn"
                  useCustomImageHandler
                  v-model="warehouse_info.remarks_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                  ></vue-editor>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Date" vid="establishment_date">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="establishment_date"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                  {{ $t('warehouse_config.est_date')}}<span class="text-danger">*</span>
                  </template>
                  <flat-pickr class="form-control"
                    v-model="warehouse_info.establishment_date"
                    :placeholder="$t('globalTrans.select_date')"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></flat-pickr>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl } from '../../../../../config/api_config'
import { warehouseInformationStore, warehouseInformationUpdate } from '../../api/routes'
import { VueEditor } from 'vue2-editor'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider,
    VueEditor
  },
  created () {
    if (this.id) {
      const tmp = this.getWarehouseInformationData()
      this.warehouse_info = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      warehouse_info: {
        warehouse_name: '',
        warehouse_name_bn: '',
        total_capacity: '',
        actual_capacity: '',
        establishment_date: '',
        warehouse_state: '',
        file_name: '',
        file_name_bn: '',
        attachment: '',
        remarks: '',
        remarks_bn: '',
        warehouse_type_id: '0',
        warehouse_category_id: '0',
        division_id: '0',
        region_id: '0',
        district_id: '0',
        upazilla_id: '0',
        union_id: '0',
        status: '0',
        created_by: 1,
        updated_by: 1
      },
      options: [
          { item: 1, name: 'New' },
          { item: 0, name: 'Old' }
        ],
      attachment_two: '',
      districtList: [],
      regionDistrictLists: [],
      upazilaList: [],
      unionList: []
    }
  },
  computed: {
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    warehouseTypeList: function () {
      return this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
    },
    warehouseCategoryList: function () {
      return this.$store.state.warehouse.warehouseCategoryList.filter(item => item.status === 0)
    },
    regionLists: function () {
      return this.$store.state.warehouse.regionList.filter(item => item.status === 0)
    }
  },
  watch: {
    'warehouse_info.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'warehouse_info.region_id': function (newVal, oldVal) {
      this.getRegionDistrictList(newVal)
    },
    'warehouse_info.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'warehouse_info.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    },
    'warehouse_info.actual_capacity': function () {
      if (this.warehouse_info.actual_capacity > this.warehouse_info.total_capacity) {
          this.$toast.error({
          title: 'Error',
          message: 'Actual Capacity is not Valid! Please, try again.'
        })
      }
    }
  },
  methods: {
    getWarehouseInformationData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    onFileChange (e) {
      this.attachment_two = e.target.files[0]
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: true }
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }

      var formData = new FormData()
      Object.keys(this.warehouse_info).map(key => {
        if (key === 'attachment') {
          formData.append(key, this.attachment_two)
        } else if (key === 'warehouse_type_id') {
            formData.append(key, parseInt(this.warehouse_info[key]))
        } else {
          formData.append(key, this.warehouse_info[key])
        }
      })
    if (this.warehouse_info.actual_capacity <= this.warehouse_info.total_capacity) {
          if (this.warehouse_info.id) {
            result = await RestApi.postData(warehouseServiceBaseUrl, `${warehouseInformationUpdate}/${this.id}`, formData)
          } else {
            result = await RestApi.postData(warehouseServiceBaseUrl, warehouseInformationStore, formData, config)
          }
      } else {
          this.$toast.error({
          title: 'Error',
          message: 'Actual Capacity is not Valid! Please, try again.'
        })
      }

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('mutateWarehouseProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.warehouse_info.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
          this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
        } else {
          this.$toast.error({
          title: 'Error',
          message: result.message
        })
        }
      }
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
     getRegionDistrictList (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
            if (response) {
                const dataObjectTemp = response.filter(document => document.region_id === regionId)
                const dataObject = dataObjectTemp.filter(item => item.status === 0)
                this.regionDistrictLists = dataObject.map(obj => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
                    } else {
                        return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
                    }
                })
            }
        })
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    }
  }
}
</script>
